body {
  margin: 0;
}

.leaflet-container {
  height: 500px;
  width: 100%;
}

.news-body img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

.ra-rich-text-input .ql-editor {
  height: 400px;
  padding: 12px !important;
}

.MuiTypography-root{
  word-break: break-word;
}

.previews {
  word-break: break-all;
}

.ql-iframe::after{
  content: url("./icons/iframe_outline_icon.svg")
}

.iframe-form input {
  display: block;
  border: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  background-color: rgba(0, 0, 0, 0.04);
  padding: 27px 12px 10px;
}

.iframe-form input:hover{
  background-color: rgba(0, 0, 0, 0.13)
}
